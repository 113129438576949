export function _postRequest(url, formData, cb, errorCb, errorMessage = 'Something went wrong!') {
  _request("POST", url, formData, cb, errorCb, errorMessage = 'Something went wrong!');
}

export function _getRequest(url, formData, cb, errorCb, errorMessage = 'Something went wrong!') {
  _request("GET", url, formData, cb, errorCb, errorMessage = 'Something went wrong!');
}
function _request(requestType, url, formData, cb, errorCb, errorMessage = 'Something went wrong!') {
  fetch(url, {
    method: requestType,
    body: formData
  })
    .then(response => {
      if (response.status !== 200) {
        throw response;
      }
      const contentType = response.headers.get("content-type");
      const contentDisposition = response.headers.get("Content-Disposition");
      let filename = '';
      if (contentDisposition && contentDisposition.includes('attachment')) {
        const filenameMatch = contentDisposition.match(/filename="?(.+)"?/);
        if (filenameMatch.length === 2) filename = filenameMatch[1];
      }
      if (contentType && contentType.includes("application/json")) {
        return response.json();
      } else if (contentType && contentType.includes("application/pdf")) {
        return response.blob().then(blob => ({ blob, filename }));
      } else if (contentType && contentType.includes("text")) {
        return response.text();
      } else {
        return response.json();
      }
    
    })
    .then(
      result => {
        if (result.stripeError) {
          window.location = './licence';
        } else if (result.hasOwnProperty('success') && !result.success) {
          errorCb(result.message ? result.message : errorMessage);
        } else if (result.hasOwnProperty('error')) {
          errorCb(result.error ? result.error : errorMessage);
        } else {
          cb(result);
        }
      },
      error => {
        console.log("error:", error)
        if (error.status === 403) {
          localStorage.removeItem('token');
          window.location = './expired-token-page';
        } else {
          errorCb(errorMessage);
        }
      }
    )
    .catch(error => {
      errorCb('Invalid request! Please try again.');
    });
}

export function _memberOnlyRequest(url, formData, cb, errorCb, errorMessage = 'Something went wrong!') {
  fetch(url, {
    method: 'POST',
    body: formData
  })
    .then(response => {
      if (response.status !== 200) {
        throw response;
      }
      return response.json();
    })
    .then(
      result => {
        if (result.stripeError) {
          errorCb({ message: result.stripeError ? result.stripeError : errorMessage, showErrorModal: true });
        } else if (result.hasOwnProperty('success') && !result.success) {
          errorCb({ message: result.message ? result.message : errorMessage, showErrorModal: false });
        } else if (result.hasOwnProperty('error')) {
          errorCb({ message: result.error ? result.error : errorMessage, showErrorModal: false });
        } else {
          cb(result);
        }
      },
      error => {
        if (error.status === 403) {
          localStorage.removeItem('token');
          window.location = './expired-token-page';
        } else {
          errorCb(errorMessage);
        }
      }
    )
    .catch(error => {
      errorCb('Invalid request! Please try again.');
    });
}
