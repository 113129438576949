import { createContext, useContext, useEffect, useState } from 'react';
import { auth } from '../services/auth';

const authContext = createContext();

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};

function useProvideAuth() {
  const [user, setUser] = useState();
  const signin = (username, password, cb, errorCb) => {
    return auth.signin(
      username,
      password,
      data => {
        localStorage.setItem('token', data.token);
        localStorage.setItem('max_models', data.max_models);
        localStorage.setItem('name', data.name);
        localStorage.setItem('username', username);
        localStorage.setItem('client_id', data.client_id);
        localStorage.setItem('role', data.role ? data.role : '');
        localStorage.setItem('supervisor', data.supervisor ? data.supervisor : '');
        localStorage.setItem('subscription_id', data.subscription_id ? data.subscription_id : '');
        localStorage.setItem('stripe_status', data.stripe_status ? data.stripe_status : '');
        localStorage.setItem('user_plan_type', data.plan_type ? data.plan_type : '');
        setUser({ name: data.name, username: username });
        cb(data);
      },
      error => {
        localStorage.removeItem('token');
        localStorage.removeItem('name');
        localStorage.removeItem('max_models');
        localStorage.removeItem('client_id');
        localStorage.removeItem('username');
        localStorage.removeItem('role');
        localStorage.removeItem('supervisor');
        localStorage.removeItem('subscription_id');
        localStorage.removeItem('stripe_status');
        localStorage.removeItem('user_plan_type');
        setUser(null);
        errorCb(error);
      }
    );
  };

  const signout = cb => {
    return auth.signout(() => {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      localStorage.removeItem('max_models');
      localStorage.removeItem('username');
      localStorage.removeItem('client_id');
      localStorage.removeItem('role');
      localStorage.removeItem('supervisor');
      localStorage.removeItem('stripe_status');
      setUser(null);
      cb();
    });
  };

  const isLoggedIn = () => {
    return localStorage.getItem('token') ? true : false;
  };

  const isSubscriptionActive = () => {
    return localStorage.getItem('stripe_status') === 'active' ? true : false;
  };

  useEffect(() => {
    if (localStorage.getItem('token')) {
      setUser({ name: localStorage.getItem('name'), username: localStorage.getItem('username') });
    } else {
      setUser(null);
    }
  }, []);

  return {
    user,
    signin,
    signout,
    isLoggedIn,
    isSubscriptionActive
  };
}
