const initialState = {
	items: {},
	isLoading: false,
	error: '',
	hasError: false,
	totalPages: 0,
	perPage: 20
};

function dataSheetReducer(state = initialState, action) {
	switch (action.type) {
		case 'loading':
			return { ...state, isLoading: true, hasError: false, error: '' };
		case 'updateList':
			return {
				...state,
				items: action.payload.items,
				totalPages: action.payload.totalPages,
				perPage: action.payload.perPage,
				isLoading: false
			};
		case 'error':
			return { ...state, isLoading: false, hasError: true, error: action.payload.error };
		default:
			return state;
	}
}

export default dataSheetReducer;
