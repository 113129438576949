import { _postRequest } from './_apiReq';
import { getAPIPrefix } from '../config/APIEndpoints';

export const dataSheet = {
  getDataSheetLatestVersion(token, scene, procedure, cb, errorCb) {
    const formData = new FormData();
    formData.append('token', token);
    formData.append('scene', scene);
    formData.append('procedure', procedure);

    _postRequest(
      getAPIPrefix() + 'data-sheet-latest-version',
      formData,
      result => cb(result),
      error => errorCb({ message: error })
    );
  },

  getDataSheets(uid, cb, errorCb) {
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    if (uid) {
      formData.append('uid', uid);
    }

    _postRequest(
      getAPIPrefix() + 'get-data-sheets',
      formData,
      result => cb(result),
      error => errorCb({ message: error })
    );
  },

  generateDataSheetPDF(uid, version, scene, procedure) {
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('uid', uid);
    formData.append('version', version);
    formData.append('scene', scene);
    formData.append('procedure', procedure);

    return new Promise((resolve, reject) => {
      _postRequest(
        getAPIPrefix() + 'generate-data-sheet-pdf',
        formData,
        result => {
          if (result.error) {
            reject(result.error);
          } else {
            resolve(result); // result now contains both blob and filename
          }
        },
        error => reject({ message: error })
      );
    });
  }
};