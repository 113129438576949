import { BrowserRouter, Routes, Route } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';
import { ProvideAuth } from './hooks/auth';
import { ProvideRegister } from './hooks/register';
import { ProvideModal } from './hooks/modal';
import SignIn from './components/user/SignIn';
import ForgotPasswordPage from './components/user/ForgotPasswordPage';
import SignUp from './components/user/SignUp';
import ConfirmEmail from './components/user/ConfirmEmail';
import ChangePasswordPage from './components/user/ChangePasswordPage';
import EditUserPage from './components/user/EditUserPage';
import ResetPasswordPage from './components/user/ResetPasswordPage';
import ExpiredTokenPage from './components/user/ExpiredTokenPage';
import PageLayout from './components/PageLayout';
import SceneList from './components/sceneManager/Index';
import EditScene from './components/sceneManager/EditScene';
import ExportSceneList from './components/sceneManager/ExportSceneList';
import AddScene from './components/sceneManager/AddScene';
import ImportingScene from './components/sceneManager/ImportingScene';
import ModelManager from './components/modelManager/Index';
import HdriManager from './components/hdriManager/Index';
import MultiMediaManager from './components/multiMediaManager/Index';
import DataSheets from './components/dataSheets/Index';
import FrameFilesManager from './components/frameFilesManager/Index';
import LicencePage from './components/subscription/LicencePage';
import EditPlan from './components/editPlan/Index';
import InvoiceListPage from './components/subscription/InvoiceListPage';
import InvoiceDetailsPage from './components/subscription/InvoiceDetailsPage';
import PrivacyPolicyPage from './components/PrivacyPolicyPage';
import TermsAndConditionsPage from './components/TermsAndConditionsPage';
import DownloadPage from './components/DownloadPage';
import NoPage from './components/NoPage';
import './scss/main.scss';
import './scss/developer.scss';


export default function App() {
  return (
    <ProvideAuth>
      <ProvideRegister>
        <ProvideModal>
          <BrowserRouter>
            <Routes>
              <Route element={<PageLayout />}>
                <Route element={<PublicRoute />}>
                  <Route index path="/" element={<SignIn />} />
                  <Route index path="/expired-token-page" element={<ExpiredTokenPage />} />
                  <Route path="/signin" element={<SignIn />} />
                  <Route path="/forgot-password" restricted={true} element={<ForgotPasswordPage />} />
                  <Route path="/reset-password" restricted={true} element={<ResetPasswordPage />} />
                  <Route path="/download" restricted={true} element={<DownloadPage />} />
                  <Route path="/signup" restricted={true} element={<SignUp />} />
                  <Route path="/confirm-email" restricted={true} element={<ConfirmEmail />} />
                  <Route path="/privacy-policy" restricted={true} element={<PrivacyPolicyPage />} />
                  <Route path="/terms-and-conditions" restricted={true} element={<TermsAndConditionsPage />} />
                </Route>
                <Route element={<PrivateRoute />}>
                  <Route path="/scene-list" element={<SceneList />} />
                  <Route path="/scene/:name" element={<EditScene />} />
                  <Route path="/add-scene" element={<AddScene />} />
                  <Route path="/model-manager" element={<ModelManager />} />
                  <Route path="/hdri-manager" element={<HdriManager />} />
                  <Route path="/file-manager" element={<MultiMediaManager />} />
                  <Route path="/data-sheets" element={<DataSheets />} />
                  <Route path="/frame-files" element={<FrameFilesManager />} />
                  <Route path="/licence" element={<LicencePage />} />
                  <Route path="/invoices" element={<InvoiceListPage />} />
                  <Route path="/invoices/:id" element={<InvoiceDetailsPage />} />
                  <Route path="/edit-plan" element={<EditPlan />} />
                  <Route path="/buy" element={<EditPlan />} />
                  <Route path="/change-password" restricted={true} element={<ChangePasswordPage />} />
                  <Route path="/edit-user" restricted={true} element={<EditUserPage />} />
                  <Route path="/export-scene-list" restricted={true} element={<ExportSceneList />} />
                  <Route path="/import-scene" restricted={true} element={<ImportingScene />} />
                </Route>
                <Route path="*" exact={true} element={<NoPage />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </ProvideModal>
      </ProvideRegister>
    </ProvideAuth>
  );
}
