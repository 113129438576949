import React, { useReducer, useRef, useEffect, useState } from 'react';
import PageWrapper from '../PageWrapper';
import { Navigate } from 'react-router-dom';
import dataSheetReducer from "../../reducers/dataSheetReducer"; // Updated import
import { dataSheet } from "../../services/dataSheet";
import { useModal } from "../../hooks/modal";
import moment from 'moment';

const perPage = 20;
let currentPage = 1;
export default function Index() {
	const [dataSheetData, dispatch] = useReducer(dataSheetReducer, {
		items: [],
		isLoading: false,
		error: '',
		hasError: false,
		totalPages: 0,
		perPage: 20
	});
	const [deleteModal, setDeletModal] = useState(null);
	const [searchTerm, setSearchTerm] = useState('');
	const fileInputRef = useRef(null);
	const modal = useModal();

	useEffect(() => {
		dispatch({ type: 'loading', payload: {} });
		_listFiles();
	}, []);

	const _listFiles = () => {
		dataSheet.getDataSheets(
			null,
			(result) => {
				const { dataSheets, success, status } = result;
				if (success && status === 200) {
					dispatch({
						type: 'updateList',
						payload: {
							items: dataSheets,
							totalPages: Math.ceil(dataSheets.length / perPage),
							perPage: perPage
						}
					});
				} else {
					dispatch({ type: 'error', payload: { error: 'Failed to fetch data sheets' } });
				}
			},
			(error) => {
				console.log("Error fetching data sheets:", error);
				dispatch({ type: 'error', payload: { error } });
			}
		);
	}

	const handlePreviousClick = () => {
		currentPage = (currentPage - 1);
		_listFiles();
	};

	const handleNextClick = () => {
		currentPage = (currentPage + 1);
		_listFiles();
	};
	
	const changeCurrentPage = (page) => {
		currentPage = page;
		_listFiles();
	}

	const filteredDataSheets = dataSheetData.items.filter(item => 
		item.uid.toLowerCase().includes(searchTerm.toLowerCase()) ||
		item.path.toLowerCase().includes(searchTerm.toLowerCase()) ||
		item.version.toLowerCase().includes(searchTerm.toLowerCase())
	);

	const paginatedDataSheets = filteredDataSheets.slice((currentPage - 1) * perPage, currentPage * perPage);
	const totalPages = Math.ceil(filteredDataSheets.length / perPage);

	if (localStorage.getItem('supervisor') && localStorage.getItem('supervisor') === 'true') {
		return (
			<PageWrapper modal={deleteModal ? deleteModal : <></>}>
				<div className={'tab-pane fade show active'} id="pills-models" role="tabpanel" aria-labelledby="pills-models-tab">

					<div className="spacer-xs-3"></div>

					<div className="search-bar">
						<input
							type="text"
							className="form-control"
							placeholder="Search data sheets..."
							value={searchTerm}
							onChange={(e) => setSearchTerm(e.target.value)}
						/>
					</div>

					<div className="spacer-xs-3"></div>

					<div className="table-wrapper table-responsive model-table">
						<table className={"table"}>
							<thead>
								<tr>
									<th scope="col">UID</th>
									<th scope="col">Scene</th>
									<th scope="col">Procedure</th>
									<th scope="col">Version</th>
									<th scope="col">Creation Date</th>
									<th scope="col">Last Updated Date</th>
									<th scope="col">Action</th>
								</tr>
							</thead>
							<tbody>
								{
									dataSheetData && dataSheetData.isLoading ? (
										<tr key="no_data_loading">
											<td colSpan="7" className="empty text-center">
												Loading...
											</td>
										</tr>
									) : dataSheetData && !dataSheetData.isLoading && paginatedDataSheets.length <= 0 ? (
										<tr key="no_data_found">
											<td colSpan="7" className="empty text-center">
												No Data Found.
											</td>
										</tr>
									) :
										paginatedDataSheets.map((item, index) => {
											if (!item) return <></>;

											return (
												<tr key={"data_model_" + item.uid}>
													<td>{item.uid}</td>
													<td>{item.path.split('/')[0]}</td>
													<td>{item.path.split('/')[1]}</td>
													<td>{item.version}</td>
													<td>{moment(item.creation_date).format('MMM D, YYYY h:mm A')}</td>
													<td>{moment(item.last_updated_date).format('MMM D, YYYY h:mm A')}</td>
													<td>
														<div className="d-flex align-items-center justify-content-center">
															{item.ready_to_download ? (
																<button
																	className="btn btn-outline-blue rounded-pill me-3"
																	onClick={async (event) => {
																		const button = event.target;
																		button.disabled = true;
																		button.innerHTML = '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...';
																		try {
																			const result = await dataSheet.generateDataSheetPDF(item.uid, item.version, item.path.split('/')[0], item.path.split('/')[1]);
																			const url = window.URL.createObjectURL(new Blob([result.blob], { type: 'application/pdf' }));
																			const link = document.createElement('a');
																			link.href = url;
																			link.setAttribute('download', result.filename || `data_sheet_${item.uid}.pdf`); // Use the filename from the response or a default name
																			document.body.appendChild(link);
																			link.click();
																			document.body.removeChild(link);
																		} catch (error) {
																			console.error('Error generating PDF:', error);
																			button.textContent = 'Failed to download';
																			button.classList.add('btn-outline-danger');
																			button.classList.remove('btn-outline-blue');
																			setTimeout(() => {
																				button.textContent = 'Download';
																				button.classList.add('btn-outline-blue');
																				button.classList.remove('btn-outline-danger');
																				button.disabled = false;
																			}, 3000);
																		} finally {
																			if (!button.classList.contains('btn-outline-danger')) {
																				button.disabled = false;
																				button.textContent = 'Download';
																			}
																		}
																	}}
																>
																	Download
																</button>
															) : (
																<button className="btn btn-outline-secondary rounded-pill me-3" disabled>
																	Not Ready
																</button>
															)}
														</div>
													</td>
												</tr>
											);
										})
								}
							</tbody>

						</table>
						<div className="spacer-xs-3"></div>
						<nav aria-label="Page navigation example">
							<ul className="pagination justify-content-center">
								<li className={'page-item link ' + (currentPage <= 1 ? 'disabled' : '')} data-current-page={currentPage}>
									<span
										className="page-link"
										tabIndex="-1"
										onClick={() => {
											if(currentPage <= 1) {
												return true;
											}else{
												handlePreviousClick(currentPage);
											}
										}}
									>
										Previous
									</span>
								</li>
									{totalPages === 0 ? (
									<li className="page-item disabled" >
										<span className="page-link ">1</span>
									</li>
								) : (
									[...Array(totalPages)].map((item, i) => {
										return (
											<li className={`page-item link ${i + 1 === currentPage ? ' active' : ' disabled'}`} key={`page-${i}`} index={`page-${i}`} onClick={() => changeCurrentPage(i+1)}>
												<span className="page-link ">{i + 1}</span>
											</li>
										);
									})
								)}
								<li
									className={'page-item link ' + (currentPage >= totalPages ? 'disabled' : '')}
									data-current-page={currentPage}
									data-total-pages={totalPages}
									data-is-last-page={currentPage >= totalPages}
								>
									<span
										className="page-link"
										onClick={() => {
											if(currentPage >= totalPages) {
												return true;
											}else{
												handleNextClick(currentPage);
											}
										}}
									>
										Next
									</span>
								</li>
							</ul>
						</nav>
					</div>
				</div>
			</PageWrapper>
		);
	} else {
		return <Navigate to={'/scene-list'} />;
	}
}
